import { useAuth0 } from '@auth0/auth0-react';

import { LogOutIcon, useJWTStore } from 'crunch-components';
import { SidebarCircleLink } from './StratosSidebar';

const StratosLogOutButton = () => {
	const { logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWTStore();

	const logout = async () => {
		await auth0Logout({ logoutParams: { returnTo: window.location.origin } });
		setJWT(null);
	};

	return (
		<SidebarCircleLink tooltip="Log out" icon={LogOutIcon} onClick={logout} />
	);
};

export default StratosLogOutButton;
