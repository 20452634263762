import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTrigger,
	Button,
	cn,
	DangerIcon,
	LoadingSpinner,
	OpenOverlayIcon,
	Text,
	Tooltip,
	TriangleRightIcon,
} from 'crunch-components';
import { useState } from 'react';
import { Strategy } from '../types/strategies';

export default function RunButton(props: {
	strategyName: Strategy['name'];
	overwriteCount: number;
	startRunMutation: () => Promise<unknown>;
	isProcessingMutation: boolean;
	isDisabled: boolean;
	disabledMessage?: string;
}) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const hoverTip = props.isDisabled
		? props.disabledMessage
		: props.overwriteCount > 0
			? 'Will reset overwrites.'
			: 'Run strategy';

	const warnFirst = props.overwriteCount > 0;

	const MainButton = () => (
		<Tooltip placement="top-start" content={hoverTip}>
			<Button
				variant="unstyled"
				className={cn(
					'flex flex-row bg-transparent items-center text-sm text-ca-gray-500 ml-0 mr-auto px-4 py-2 !opacity-100 rounded group',
					!props.isDisabled && 'hover:bg-ca-gray-200',
					props.isDisabled && 'text-ca-gray-500-50',
				)}
				disabled={props.isDisabled}
				onClick={() => {
					if (warnFirst) {
						return;
					}
					props.startRunMutation();
				}}
			>
				<span className="mr-2.5">Start new run</span>
				<div className="w-4 h-4 relative overflow-hidden">
					<span
						className={cn(
							'absolute transition duration-300 grid place-content-center w-full h-full',
							!props.isDisabled && warnFirst && 'group-hover:translate-x-6',
							!props.isDisabled && warnFirst && isDialogOpen && 'translate-x-6',
						)}
					>
						<TriangleRightIcon className={cn('h-3 scale-x-150')} />
					</span>
					<OpenOverlayIcon
						className={cn(
							'absolute h-4 w-4 -translate-x-6  transition duration-300',
							!props.isDisabled && warnFirst && 'group-hover:translate-x-0',
							!props.isDisabled && warnFirst && isDialogOpen && 'translate-x-0',
						)}
					/>
				</div>
			</Button>
		</Tooltip>
	);

	if (warnFirst) {
		return (
			<AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<AlertDialogTrigger>
					<MainButton />
				</AlertDialogTrigger>
				<AlertDialogContent className="bg-white flex p-2">
					<div className=" rounded-md bg-ca-gray-300 px-6 flex items-center text-ca-gray-500 ">
						<DangerIcon className="h-10 w-10" />
					</div>
					<div>
						<AlertDialogHeader className="text-lg mb-3 mt-1">
							Overwrites will be reset
						</AlertDialogHeader>
						<Text type="secondary">
							&quot;{props.strategyName}&quot; strategy has{' '}
							{props.overwriteCount} products where the recommended markdown was
							overwritten by a user. Running the strategy will reset these
							overwrites.
						</Text>

						<AlertDialogFooter className="mt-4 flex items-center">
							{props.isProcessingMutation && (
								<LoadingSpinner className="h-4 w-4" />
							)}
							<AlertDialogCancel className="relative">
								<Button variant="ghostFill">Cancel</Button>
							</AlertDialogCancel>
							<AlertDialogAction>
								<Button
									variant="primary"
									disabled={props.isProcessingMutation}
									onClick={(e) => {
										e?.preventDefault();
										props.startRunMutation().then(() => setIsDialogOpen(false));
									}}
								>
									Reset & run
								</Button>
							</AlertDialogAction>
						</AlertDialogFooter>
					</div>
				</AlertDialogContent>
			</AlertDialog>
		);
	}

	return <MainButton />;
}
