import { paths } from '../../types/backend-api';
import api from './api';

export type ChannelsDataType =
	paths['/v2/channels']['get']['responses']['200']['content']['application/json'];

export const GET_CHANNELS = () => {
	return api
		.get('api/v2/channels')
		.json<
			paths['/v2/channels']['get']['responses']['200']['content']['application/json']
		>();
};
