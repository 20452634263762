import { COPY_BUSINESS_RULE } from 'api/business-rules';
import useChannelStore from 'hooks/channels/useChannelStore';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import { LIST_BUSINESS_RULES_QUERY_KEY } from './useBusinessRulesQuery';

const COPY_BUSINESS_RULE_MUTATION_KEY = ['businessRuleCopy'];

export const useBusinessRuleCopy = (
	id: string,
	onSuccess?: UseMutationOptions['onSuccess'],
) => {
	const { activeChannel } = useChannelStore();
	const qc = useQueryClient();
	return useMutation({
		mutationKey: constructChannelQueryKey(activeChannel, [
			...COPY_BUSINESS_RULE_MUTATION_KEY,
			id,
		]),
		mutationFn: async () => {
			return COPY_BUSINESS_RULE(id);
		},
		onSuccess: (...args) => {
			qc.invalidateQueries(
				constructChannelQueryKey(activeChannel, LIST_BUSINESS_RULES_QUERY_KEY),
			);

			if (onSuccess) {
				onSuccess(...args);
			}
		},
	});
};
