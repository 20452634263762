import { RouterProvider } from 'react-router-dom';
import DevelopmentWrapper from 'routing/components/DevelopmentWrapper';

import { ToastQueue } from 'crunch-components';
import BaseProviders from 'routing/components/BaseProviders';
import router from 'routing/routing';

export default function App() {
	return (
		<BaseProviders>
			<DevelopmentWrapper>
				<RouterProvider router={router} />
				<ToastQueue />
			</DevelopmentWrapper>
		</BaseProviders>
	);
}
