import { LuzmoDashboardComponent } from '@luzmo/react-embed';
import {
	GET_PRODUCT,
	GET_PRODUCT_MARKDOWN_RECOMMENDATIONS,
} from 'api/products';
import { GET_CUMULIO_RECOMMENDATIONS_PRODUCT_SSO } from 'api/reports';
import { Button, CrossIcon, Text, cn } from 'crunch-components';
import { get, random } from 'crunch-utils';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';

const TableDataSkeletonLoader = ({ ...props }) => {
	return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90].map((val) => (
		<td key={`skeleton_loader_${val}`} {...props}>
			<div className="w-full h-3 bg-ca-silver rounded-full animate-pulse" />
		</td>
	));
};

const RecommendationsDetail = ({ id, onClose }) => {
	const ref = useRef(null);
	const { activeStrategy } = useStrategyStore();

	const { isLoading: isProductLoading, data: product } = useChannelQuery(
		['product', id],
		() => GET_PRODUCT(id),
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const { isLoading: isTableLoading, data: table = [] } = useChannelQuery(
		['product-markdown-recommendations', activeStrategy, id],
		() => GET_PRODUCT_MARKDOWN_RECOMMENDATIONS(activeStrategy, id),
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const { data: graph, isFetching: isGraphFetching } = useChannelQuery(
		['cumulio-recommendations-product', activeStrategy, id],
		() => GET_CUMULIO_RECOMMENDATIONS_PRODUCT_SSO(activeStrategy, id),
		{
			staleTime: 5 * 60 * 1000,
			refetchOnReconnect: true,
		}
	);

	useEffect(() => {
		// don't think this is needed at all
		const asyncReload = async () => {
			if(ref?.current?.iframeLoaded) {
				await ref?.current?.reloadDashboard();
			}
		};
		asyncReload();
	}, [graph?.token]);

	const fields = {
		brand: 'Brand',
		code: 'Product code',
		color: 'Color',
		collection: 'Collection',
		season: 'Season',
		target: 'Target',
		product_group: 'Product group(s)',
		cost: 'Cost',
		price: 'Full price',
		inventory: 'Inventory',
		description: 'Description',
	};

	// generate random widths that stay consistent in-between re-renders for the skeleton loaders
	const randomWidths = useMemo(
		() =>
			Object.keys(fields).reduce(
				(acc, val) => ({ ...acc, [val]: random(40, 100) }),
				{}
			),
		[]
	);

	return (
		<div className="p-10">
			<Button
				size="small"
				variant="unstyled"
				className="absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 rounded-full flex items-center font-bold text-white bg-ca-gray py-2 px-3 text-xxs focus-visible:bg-ca-gray-a hover:bg-ca-gray-a active:bg-ca-gray-a"
				onClick={onClose}
			>
				Product details <CrossIcon className="ml-1 h-2" />
			</Button>
			<div className="flex space-x-8">
				<div className="flex flex-col space-y-4 w-64">
					{isProductLoading ? (
						<>
							{/* loading skeleton */}
							<div className="w-full h-64 bg-ca-silver rounded-lg animate-pulse" />
							{Object.entries(fields).map(([key, label]) => (
								<div key={key} className="flex justify-between items-center">
									<span className="h-4 text-transparent bg-ca-silver rounded animate-pulse">
										{label}
									</span>
									<span
										className="h-4 bg-ca-silver rounded animate-pulse"
										style={{ width: `${randomWidths?.[key]}px` }}
									/>
								</div>
							))}
						</>
					) : (
						<>
							{product?.image_url && (
								<img className="w-full" src={product?.image_url} alt="" />
							)}
							{Object.entries(fields).map(
								([key, label, value = get(product, key)]) =>
									value !== undefined && (
										<div key={key} className="flex justify-between items-start">
											<Text size="text-xs" type="secondary" className="mr-8">
												{label}
											</Text>
											{Array.isArray(value) ? (
												<Text
													size="text-xs"
													className="text-right font-bold flex flex-col space-y-1"
												>
													{value.map((v) => (
														<span key={v}>{v}</span>
													))}
												</Text>
											) : (
												<Text
													size="text-xs"
													className="text-right font-bold break-words"
												>
													{value}
												</Text>
											)}
										</div>
									)
							)}
						</>
					)}
				</div>
				<div className="flex flex-col justify-items-stretch grow">
					<table className="w-full table-auto text-xs text-ca-black">
						<thead>
							<tr>
								<th className={cn(isTableLoading && 'w-40')}>
									<span />
								</th>
								{!isTableLoading &&
									table?.map(({ markdown }) => (
										<th
											key={markdown}
											className="pb-2 pl-4 text-right font-bold "
										>
											{Math.round(markdown * 100)}%
										</th>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="pb-2 pl-4" />
								)}
							</tr>
						</thead>
						<tbody className="border-t border-b border-ca-silver">
							<tr>
								<td className="pb-2 pt-4 text-left font-bold">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Sales'
									)}
								</td>
								{!isTableLoading &&
									table?.map(({ markdown, sales }) => (
										<td
											key={`${markdown}_${sales}`}
											className="pb-2 pt-4 pl-4 text-right"
										>
											{sales}
										</td>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="pb-2 pt-4 pl-4" />
								)}
							</tr>
							<tr>
								<td className="py-2 text-left font-bold ">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Turnover'
									)}
								</td>
								{!isTableLoading &&
									table?.map(({ markdown, turnover }) => (
										<td
											key={`${markdown}_${turnover}`}
											className="py-2 pl-4 text-right"
										>
											{turnover}
										</td>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="py-2 pl-4" />
								)}
							</tr>
							<tr>
								<td className="py-2 text-left font-bold ">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Margin'
									)}
								</td>
								{!isTableLoading &&
									table?.map(({ markdown, margin }) => (
										<td
											key={`${markdown}_${margin}`}
											className="py-2 pl-4 text-right"
										>
											{margin}
										</td>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="py-2 pl-4" />
								)}
							</tr>
							<tr>
								<td className="py-2 text-left font-bold ">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Objective'
									)}
								</td>
								{!isTableLoading &&
									table?.map(({ markdown, objective }) => (
										<td
											key={`${markdown}_${objective}`}
											className="py-2 pl-4 text-right"
										>
											{objective}
										</td>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="py-2 pl-4" />
								)}
							</tr>
							<tr>
								<td className="py-2 text-left font-bold ">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Rest stock'
									)}
								</td>
								{!isTableLoading &&
									table?.map(({ markdown, rest_stock: restStock }) => (
										<td
											key={`${markdown}_${restStock}`}
											className="py-2 pl-4 text-right"
										>
											{restStock}
										</td>
									))}
								{isTableLoading && (
									<TableDataSkeletonLoader className="pt-2 pb-4 pl-4" />
								)}
							</tr>
							<tr>
								<td className="pt-2 pb-4 text-left font-bold ">
									{isTableLoading ? (
										<div className="h-3 bg-ca-silver rounded-full animate-pulse" />
									) : (
										'Sell-through'
									)}
								</td>
								{!isTableLoading &&
									table?.map(
										({
											markdown,
											sell_through_percentage: sellThroughPercentage,
										}) => (
											<td
												key={`${markdown}_${sellThroughPercentage}`}
												className="pt-2 pb-4 pl-4 text-right"
											>
												{sellThroughPercentage}
											</td>
										)
									)}
								{isTableLoading && (
									<TableDataSkeletonLoader className="pt-2 pb-4 pl-4" />
								)}
							</tr>
						</tbody>
					</table>
					<div className="relative grow">
						{graph?.dashboard_id && !isGraphFetching && (
							<LuzmoDashboardComponent
								ref={ref}
								dashboardId={graph?.dashboard_id}
								authKey={graph?.id}
								authToken={graph?.token}
								loaderBackground="#FFFFFF"
								loaderFontColor="transparent"
								loaderSpinnerColor="#6111C7"
								loaderSpinnerBackground="transparent"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

RecommendationsDetail.propTypes = {
	id: PropTypes.string.isRequired,
	onClose: PropTypes.func,
};

RecommendationsDetail.defaultProps = {
	onClose: () => { },
};

export default RecommendationsDetail;
