import { Button, Tag, TagColor, Tooltip } from 'crunch-components';
import { pluralise } from 'crunch-utils';
import { useState } from 'react';

const BusinessRuleSet = ({ row, colId, numberBusinessRulesShown = 3 }) => {
	const [isShown, setIsShown] = useState(false);
	const handleClick = () => {
		setIsShown((s) => !s);
	};

	const businessRulesList = row?.[colId] || [];
	const priorities = row?.[`${colId}_priority`] || [];

	// Combine business rules with priorities
	const combinedBusinessRules = businessRulesList.map((rule, index) => ({
		rule,
		priority: priorities[index],
	}));

	// Sort based on priority while preserving the original order
	const sortedBusinessRules = combinedBusinessRules.sort(
		(a, b) => a.priority - b.priority
	);

	const businessRuleListJSX = sortedBusinessRules.map((businessRule) => (
		<Tag
			key={businessRule.rule}
			label={businessRule.rule}
			color={TagColor.Grey}
		/>
	));

	return (
		<div className="flex flex-col items-stretch">
			{businessRuleListJSX?.length <= numberBusinessRulesShown ? (
				<>{businessRuleListJSX}</>
			) : (
				<>
					{businessRuleListJSX?.slice(0, numberBusinessRulesShown)}
					{isShown && businessRuleListJSX?.slice(numberBusinessRulesShown)}
					<Button variant="unstyled" onClick={handleClick}>
						<div className="flex justify-center mt-1 text-xs">
							{!isShown ? (
								<Tooltip
									content={
										<>
											{pluralise(
												businessRuleListJSX?.length - numberBusinessRulesShown,
												'more rule',
												'more rules'
											)}
										</>
									}
									placement="right"
								>
									<span>
										<text className="underline">show more...</text>
									</span>
								</Tooltip>
							) : (
								<text className="underline">show less</text>
							)}
						</div>
					</Button>
				</>
			)}
		</div>
	);
};

export default BusinessRuleSet;
