import { paths } from '../../types/backend-api';
import api from './api';

export type RecommendationsDataType =
	paths['/v2/recommendations/schema']['get']['responses']['200']['content']['application/json'];

export const GET_RECOMMENDATIONS_SCHEMA = () => {
	return api
		.get(`api/v2/recommendations/schema`)
		.json<RecommendationsDataType>();
};

export const GET_OVERWRITE_COUNTS_QUERY_KEY = ['all-overwrite-counts'] as const;
export type OverwriteCountsDataType =
	paths['/v2/recommendations/manual-overwrites/count-per-strategy']['get']['responses']['200']['content']['application/json'];
export const GET_OVERWRITE_COUNTS = () => {
	return api
		.get('api/v2/recommendations/manual-overwrites/count-per-strategy')
		.json<OverwriteCountsDataType>();
};
