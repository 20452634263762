import { useAuth0 } from '@auth0/auth0-react';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CircularProgress, Modal, Text, useModal } from 'crunch-components';

import { GET_MY_ACCESS } from 'api/users';
import NoAccess from 'pages/no-access/NoAccess';
import useChannelStore from '../hooks/channels/useChannelStore';

const ChannelContext = createContext(null);

const ChannelProvider = (props: PropsWithChildren<{}>): JSX.Element => {
	const [counter, setCounter] = useState(0);
	const {
		setChannels,
		activeChannel,
		setActiveChannel,
		reset: resetChannelStore,
	} = useChannelStore();
	const [prevActiveChannel, setPrevActiveChannel] = useState(activeChannel);
	const { open, close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};

	const { isAuthenticated } = useAuth0();

	const { isFetching } = useQuery('my-access', GET_MY_ACCESS, {
		enabled: isAuthenticated,
		onSuccess: ({ channels }) => {
			if (channels.length === 0) {
				resetChannelStore();
			}

			setChannels(channels);
			// check if the active channel from cache exists
			const active = channels.find((c) => c === activeChannel);

			// if no active channel was set, default set it to the first channel in the list
			if (!active) {
				setActiveChannel(channels[0] ?? '');
			}
		},
	});

	useEffect(() => {
		// active channel changed
		if (activeChannel && activeChannel !== prevActiveChannel) {
			setPrevActiveChannel(activeChannel);
			open({
				modalNode: (
					<Modal.Root>
						<Modal.Content>
							<Modal.Title>
								<span className="block text-center">Switching channel</span>
							</Modal.Title>
							<div className="flex flex-wrap justify-center text-center space-y-8">
								<CircularProgress
									onAnimationEnded={() => setCounter((i) => i + 1)}
								/>
								<Text className="w-full">
									Just a moment please. This won&apos;t take long.
								</Text>
							</div>
						</Modal.Content>
					</Modal.Root>
				),
			});
		}
	}, [activeChannel, prevActiveChannel]);

	// close the loading modal after a single iteration of the spinner
	if (counter >= 1) {
		close();
		setCounter(0);
	}

	if (isFetching && activeChannel === '') {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	if (activeChannel === '') {
		return <NoAccess />;
	}

	return <>{props.children}</>;
};

export { ChannelContext, ChannelProvider };
