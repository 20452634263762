import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import cn from '../../utils/cn';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import DragDropIcon from '../Icons/DragDrop';
import Info from '../Icons/Info';
import TriangleDownIcon from '../Icons/TriangleDown';
import TriangeLeftIcon from '../Icons/TriangleLeft';
import TriangleRightIcon from '../Icons/TriangleRight';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
/** TABLE STYLING MAGIC
 * add background-color and border
 * 		border on row
 * 		bg-color must be on table cells, not row
 * 		AND cell must have 'relative' so the tr.border will render above td/th.border
 * */
const BORDER_CLASSNAME = 'border-b border-gray-200';
const NOTLAST_BORDER_CLASSNAME = 'not-last:border-b not-last:border-gray-200 not-last:relative';
export const CELL_PADDING_CLASSNAME = 'p-3';
export const CHILD_ROWS_DEFAULT_STYLING = {
    childRowClassName: 'bg-gray-100 hover:bg-[#eeeff2] transition-all duration-300 ease-out border-y border-gray-200',
    fontClassName: 'text-right text-ca-black text-xs',
};
const DragDropCell = ({ disabled, onMouseEnter, onMouseLeave, key, dragRef, }) => {
    return (_jsx("td", { className: cn('p-0  cursor-grab  transition-all relative w-7 bg-gray-100 ', disabled
            ? 'bg-ca-gray-200 group-hover/row:hover:bg-ca-gray-300 cursor-not-allowed'
            : `bg-ca-gray-200 group-hover/row:bg-ca-purple group-hover/row:hover:bg-purple-200 group-hover/row:bg-opacity-10 group-active/row:bg-ca-purple group-active/row:bg-opacity-10`, 'text-ca-gray-400 group-hover/row:text-ca-purple group-hover/row:text-opacity-30 '), ref: dragRef, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, children: _jsx("div", { className: "flex justify-center items-stretch ", children: !disabled && _jsx(DragDropIcon, { className: "w-2.5" }) }) }, key));
};
const TableRow = ({ headings, index, row, rowKey, disabled, selected, selectable, loading, handleCheckedChanged, handleRowMovedPreview, handleRowMoved, renderCell, dragDisabled, dndType, dndAccepts, handleClick, handleMouseOver, handleMouseLeave, }) => {
    const ref = useRef(null);
    const [canDrag, setCanDrag] = useState(false);
    const [{ handlerId }, drop] = useDrop({
        accept: dndAccepts ?? 'TABLE_ROW',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // dont allow dragging of items that have their index present in `dragDisabled`
            if (dragDisabled.includes(dragIndex) ||
                dragDisabled.includes(hoverIndex)) {
                return;
            }
            handleRowMovedPreview(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: dndType ?? 'TABLE_ROW',
        canDrag: canDrag && !dragDisabled.includes(index),
        item: () => {
            return { id: row?.[rowKey], index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: handleRowMoved,
    });
    const isSelectable = selectable && !loading;
    drag(drop(ref));
    return (_jsxs("tr", { ref: ref, className: cn('relative transition-colors group/row', NOTLAST_BORDER_CLASSNAME, disabled.includes(row?.[rowKey]) && 'opacity-50 cursor-not-allowed', isDragging && 'opacity-50 bg-purple-50 cursor-grabbing bg-opacity-20', handleClick && !isDragging && 'cursor-pointer'), onClick: handleClick ? (event) => handleClick(row, event) : undefined, onMouseOver: handleMouseOver ? () => handleMouseOver(row) : undefined, onMouseLeave: handleMouseLeave ? () => handleMouseLeave(row) : undefined, onFocus: handleMouseOver ? () => handleMouseOver(row) : undefined, "data-handler-id": handlerId, children: [isSelectable && (_jsx("td", { className: "pl-5 pr-3", children: _jsx(Checkbox, { disabled: disabled.includes(row?.[rowKey]), checked: selected.includes(row?.[rowKey]), onChange: (checked) => handleCheckedChanged(row?.[rowKey], checked) }) }, `selectable${row?.[rowKey]}`)), headings.map((col, colIndex) => {
                if (col.id === 'dragdrop') {
                    return (_jsx(DragDropCell, { disabled: dragDisabled.includes(index), onMouseEnter: () => setCanDrag(true), onMouseLeave: () => setCanDrag(false), dragRef: drag }, `${row?.[rowKey]}_dragdrop`));
                }
                return (_jsx("td", { className: cn(`text-sm text-ca-black leading-none whitespace-nowrap select-text relative transition-colors`, CELL_PADDING_CLASSNAME, handleClick
                        ? `group-hover/row:bg-purple-50`
                        : 'group-hover/row:bg-gray-50', {
                        'text-left': col?.align === 'left' || !col?.align,
                        'text-right': col?.align === 'right',
                        'text-center': col?.align === 'center',
                    }, colIndex === 0 && !isSelectable && 'pl-5', colIndex === headings.length - 1 && 'pr-5'), children: renderCell(row, col.id, index) }, `${row?.[rowKey]}_${col.id}`));
            })] }, `${row?.[rowKey]}`));
};
TableRow.propTypes = {
    headings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        tooltip: PropTypes.string,
        sortable: PropTypes.bool,
        align: PropTypes.oneOf(['left', 'center', 'right']),
    })),
    index: PropTypes.number.isRequired,
    row: PropTypes.any,
    rowKey: PropTypes.string,
    renderCell: PropTypes.func,
    handleCheckedChanged: PropTypes.func,
    handleRowMoved: PropTypes.func,
    handleRowMovedPreview: PropTypes.func,
    handleClick: PropTypes.func,
    handleMouseOver: PropTypes.func,
    disabled: PropTypes.array,
    selected: PropTypes.array,
    loading: PropTypes.bool,
    selectable: PropTypes.bool,
    dragDisabled: PropTypes.arrayOf(PropTypes.number),
    /**
     * What the item type is of this row in context of the drag and drop system.
     */
    dndType: PropTypes.string,
    /**
     * What item type is accepted to be dropped at this row in context of the drag and drop system.
     */
    dndAccepts: PropTypes.arrayOf(PropTypes.string),
};
TableRow.defaultProps = {
    headings: [],
    row: {},
    rowKey: 'id',
    renderCell: (row, columnId) => {
        row?.[columnId]?.toString();
    },
    selectable: false,
    selected: [],
    handleCheckedChanged: () => { },
    handleRowMoved: () => { },
    handleRowMovedPreview: () => { },
    disabled: [],
    loading: false,
    dragDisabled: [],
};
const Table = ({ className, headings, rows: sourceRows, rowKey, dndRowTypes, dndRowAccepts, separatorRows, renderCell, pagination, onPageChange, onItemsPerPageChange, sort, onSortChange, selectable, selected, onSelectedChange, onDragChange, onRowClick, onRowMouseOver, onRowMouseLeave, disabled, dragDisabled, open, renderOpen, renderOpenTr, loading, itemsLoading, emptyState, isInline, renderAfterRow, maxHeightTable, }) => {
    const [rows, setRows] = useState([]);
    const allChecked = !rows.find((row) => !selected.includes(row?.[rowKey]));
    useEffect(() => {
        setRows(() => sourceRows);
    }, [sourceRows]);
    const handleCheckedChanged = (id, checked) => {
        if (checked) {
            onSelectedChange([...selected, id]);
        }
        else {
            onSelectedChange(selected.filter((rId) => rId !== id));
        }
    };
    const handleCheckAll = () => {
        if (allChecked) {
            onSelectedChange(selected.filter((s) => !rows.find((row) => row?.[rowKey] === s)));
        }
        else {
            onSelectedChange([...selected, ...rows.map((row) => row?.[rowKey])]
                .filter(
            // filter out disabled rows
            (value) => !disabled.includes(value))
                .filter(
            // filter out duplicates
            (value, index, self) => self.indexOf(value) === index));
        }
    };
    const handleRowMoved = () => {
        onDragChange(rows?.map(({ [rowKey]: rKey }) => rKey));
    };
    const handleRowMovedPreview = (dragIndex, hoverIndex) => {
        const updatedRows = update(rows, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, rows[dragIndex]],
            ],
        });
        setRows(updatedRows);
    };
    if (!headings.length)
        return null;
    const isSelectable = selectable && !loading;
    return (_jsx(DndProvider, { backend: HTML5Backend, context: window, children: _jsxs("div", { className: cn('relative bg-white shadow-xl', !isInline ? 'rounded-lg shadow-ca-hack-silver overflow-hidden' : ''), children: [_jsx("div", { className: (cn('overflow-x-auto'), maxHeightTable), children: _jsxs("table", { className: cn('w-full table-auto relative', className), children: [_jsx("thead", { className: "relative after:content[''] after:absolute after:h-[1px] after:bg-ca-silver after:bottom-0 after:right-0 after:left-0 after:z-10", children: _jsxs("tr", { className: cn('w-full bg-white z-10 top-0 sticky', BORDER_CLASSNAME), children: [isSelectable && (_jsx("th", { className: "pl-5 pr-3 relative", children: _jsx(Checkbox, { checked: allChecked, onChange: handleCheckAll }) }, "check")), headings.map((col, colIndex) => {
                                            if (col.id === 'dragdrop') {
                                                return (_jsx("th", { className: cn('bg-ca-ghost-white relative', col?.maxWidth && `w-[${col?.maxWidth}]`), style: { maxWidth: col?.maxWidth ?? 'unset' } }, col.id));
                                            }
                                            return (_jsxs("th", { className: cn('p-3 font-bold text-xs text-ca-black leading-none ', {
                                                    'text-left': col?.align === 'left' || !col?.align,
                                                    'text-right': col?.align === 'right',
                                                    'text-center': col?.align === 'center',
                                                }, colIndex === 0 && !isSelectable && 'pl-5', colIndex === headings.length - 1 && 'pr-5', col?.sortable && 'cursor-pointer', col.className), onClick: () => {
                                                    if (!col?.sortable)
                                                        return;
                                                    if (col?.id === sort?.key) {
                                                        onSortChange({
                                                            key: col?.id,
                                                            direction: sort?.direction === 'asc' ? 'desc' : 'asc',
                                                        });
                                                    }
                                                    else {
                                                        onSortChange({
                                                            key: col?.id,
                                                            direction: 'desc',
                                                        });
                                                    }
                                                }, style: {
                                                    maxWidth: col?.maxWidth ?? 'unset',
                                                }, children: [_jsx(Tooltip, { content: col?.tooltip, children: _jsxs("span", { className: "inline-flex gap-2 items-center", children: [col?.label, col?.tooltip && (_jsx(Info, { className: "h-4 text-gray-400" }))] }) }), col?.id === sort?.key && (_jsx(TriangleDownIcon, { className: cn('inline ml-2 w-2.5 text-ca-gray', sort?.direction === 'asc' && 'transform rotate-180') }))] }, col.id));
                                        })] }) }), _jsxs("tbody", { className: "min-h-12 ", children: [!loading && !rows.length && emptyState ? (_jsx("tr", { className: "relative", children: _jsx("td", { className: "text-center", colSpan: "1000", children: _jsx(Text, { className: "py-24", children: emptyState }) }) })) : null, !loading && (!!rows.length || emptyState)
                                        ? rows.map((row, index) => (_jsxs(Fragment, { children: [(() => {
                                                    const separators = separatorRows.filter((separatorHay) => separatorHay.position === index);
                                                    if (!separators) {
                                                        return null;
                                                    }
                                                    return separators.map((separator) => (_jsxs("tr", { className: cn(' text-gray-500 text-xs   transition-colors', BORDER_CLASSNAME, separator.className), children: [separator.colStart !== undefined ||
                                                                (separator.colStart > 0 && (_jsx("td", { colSpan: separator.colStart }, "spacer"))), _jsx("td", { colSpan: separator.colSpan ?? 1000, className: "py-1 px-10 bg-gray-100 relative hover:bg-gray-200", children: separator.renderSeparatorRow() }, "content")] }, `separator${separator.key}`)));
                                                })(), _jsxs(Fragment, { children: [_jsx(TableRow, { index: index, row: row, rowKey: rowKey, headings: headings, disabled: disabled, selected: selected, open: open, selectable: selectable, loading: loading, handleCheckedChanged: handleCheckedChanged, handleClick: onRowClick, handleMouseOver: onRowMouseOver, handleMouseLeave: onRowMouseLeave, renderCell: renderCell, renderOpen: renderOpen, handleRowMovedPreview: handleRowMovedPreview, handleRowMoved: handleRowMoved, dragDisabled: dragDisabled, dndType: dndRowTypes && dndRowTypes[index], dndAccepts: dndRowTypes && dndRowAccepts[index] }), (() => {
                                                            const isOpen = open?.includes(row?.[rowKey]);
                                                            if (!isOpen) {
                                                                return null;
                                                            }
                                                            if (!renderOpenTr) {
                                                                return (_jsx("tr", { className: cn('relative'), children: _jsx("td", { colSpan: headings.length + (selectable ? 1 : 0), children: renderOpen(row?.[rowKey]) }) }, `${row?.[rowKey]}open${Date.now()}`));
                                                            }
                                                            return renderOpenTr(row, (...args) => renderOpen(args));
                                                        })(), renderAfterRow ? renderAfterRow({ row, index }) : null] }, row?.[rowKey])] }, `fragement${row?.[rowKey]}`)))
                                        : null, loading &&
                                        Array(itemsLoading)
                                            .fill(0)
                                            .map((i, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        _jsx("tr", { className: "border-b border-ca-silver relative", children: headings.map((col, colIndex) => {
                                                if (col.id === 'dragdrop') {
                                                    return (_jsx(DragDropCell, { disabled: true }, `skeleton_loader_${index}_${col.id}`));
                                                }
                                                return (_jsx("td", { className: cn(CELL_PADDING_CLASSNAME, colIndex === 0 && 'pl-5', colIndex === headings.length - 1 && 'pr-5'), children: _jsx("div", { className: "w-full h-6 bg-ca-silver rounded-full animate-pulse" }) }, `skeleton_loader_${index}_${col.id}`));
                                            }) }, `skeleton_loader_${index}`)))] })] }) }), !!Object.keys(pagination).length && (_jsxs("div", { className: "flex justify-between border-t border-ca-silver", children: [_jsx("div", { className: "flex items-center space-x-2 ml-2", children: pagination?.itemsPerPageOptions && (_jsxs(_Fragment, { children: [_jsx(Dropdown, { onChange: onItemsPerPageChange, value: pagination.itemsPerPage, options: pagination.itemsPerPageOptions.map((n) => ({
                                            value: n,
                                            label: n,
                                        })), size: "small", dropUpDown: "up" }), _jsx(Text, { type: "secondary", size: "text-xs", children: "results per page" })] })) }), _jsxs("div", { className: "flex divide-x divide-x-ca-silver", children: [_jsx(Pagination, { className: "flex items-center mr-2.5", current: pagination.currentPage, total: pagination.items, pageSize: pagination.itemsPerPage, onChange: onPageChange, locale: "en", itemRender: (current, type) => {
                                        if (type === 'page' ||
                                            type === 'jump-prev' ||
                                            type === 'jump-next') {
                                            return (_jsx("button", { type: "button", className: cn('py-0.5 px-2 mx-1 text-xs tabular-nums cursor-pointer rounded transition-colors', current === pagination.currentPage
                                                    ? 'font-bold text-white bg-ca-purple'
                                                    : 'text-ca-black hover:bg-ca-silver'), children: type === 'page' ? current : '...' }, current));
                                        }
                                        return null;
                                    } }), _jsx("button", { type: "button", onClick: () => onPageChange(Math.max(pagination.currentPage - 1, 1)), className: cn('p-4 text-ca-gray transition-colors', pagination.currentPage === 1
                                        ? 'cursor-not-allowed'
                                        : 'hover:text-ca-black'), disabled: pagination.currentPage === 1, children: _jsx(TriangeLeftIcon, { className: "w-2" }) }), _jsx("button", { type: "button", onClick: () => onPageChange(Math.min(pagination.currentPage + 1, Math.ceil(pagination.items / pagination.itemsPerPage))), className: cn('p-4 text-ca-gray', pagination.currentPage ===
                                        Math.ceil(pagination.items / pagination.itemsPerPage)
                                        ? 'cursor-not-allowed'
                                        : 'hover:text-ca-black'), disabled: pagination.currentPage ===
                                        Math.ceil(pagination.items / pagination.itemsPerPage), children: _jsx(TriangleRightIcon, { className: "w-2" }) })] })] }))] }) }));
};
Table.propTypes = {
    className: PropTypes.string,
    headings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        tooltip: PropTypes.string,
        sortable: PropTypes.bool,
        align: PropTypes.oneOf(['left', 'center', 'right']),
        maxWidth: PropTypes.string,
    })),
    rows: PropTypes.arrayOf(PropTypes.any),
    rowKey: PropTypes.string,
    /**
     * [Optional]
     * Dnd `type` per row
     * If empty, dnd enabled everywhere
     */
    dndRowTypes: PropTypes.arrayOf(PropTypes.string),
    /**
     * [Optional]
     * Dnd `accepts` types per row
     * If empty, dnd enabled everywhere
     */
    dndRowAccepts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    /**
     * Insert rows at position which you can fully render.
     */
    separatorRows: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        colSpan: PropTypes.number,
        colStart: PropTypes.number,
        className: PropTypes.string,
        renderSeparatorRow: PropTypes.func.isRequired,
    })),
    renderCell: PropTypes.func,
    renderAfterRow: PropTypes.func,
    pagination: PropTypes.shape({
        currentPage: PropTypes.number,
        items: PropTypes.number,
        itemsPerPage: PropTypes.number,
        itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    }),
    onPageChange: PropTypes.func,
    onItemsPerPageChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowMouseOver: PropTypes.func,
    onRowMouseLeave: PropTypes.func,
    sort: PropTypes.shape({
        key: PropTypes.string,
        direction: PropTypes.oneOf(['asc', 'desc']),
    }),
    onSortChange: PropTypes.func,
    selectable: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    onSelectedChange: PropTypes.func,
    onDragChange: PropTypes.func,
    dragDisabled: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    open: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    renderOpen: PropTypes.func,
    /**
     * Override for default render behaviour of open part of an 'open' row.
     * pass a function here which receives the row which is open as parameter.
     */
    renderOpenTr: PropTypes.func,
    loading: PropTypes.bool,
    itemsLoading: PropTypes.number,
    emptyState: PropTypes.string,
    isInline: PropTypes.bool,
    maxHeightTable: PropTypes.string,
};
Table.defaultProps = {
    headings: [],
    rows: [],
    rowKey: 'id',
    separatorRows: [],
    renderCell: (row, columnId) => {
        row?.[columnId]?.toString();
    },
    pagination: {},
    onPageChange: () => { },
    onItemsPerPageChange: () => { },
    sort: {},
    onSortChange: () => { },
    selectable: false,
    selected: [],
    onSelectedChange: () => { },
    onDragChange: () => { },
    dragDisabled: [],
    disabled: [],
    open: [],
    renderOpen: null,
    renderOpenTr: () => undefined,
    loading: false,
    itemsLoading: 10,
    emptyState: '',
    isInline: false,
    maxHeightTable: '',
};
export default Table;
