import StratosLogOutButton from 'components/StratosSidebar/StratosLogOutButton';
import { Logo, Page, Text } from 'crunch-components';

// TODO: fix scrolling
// TODO: fix email when moved
export default function NoAccess() {
	return (
		<Page>
			<Logo className="w-12 h-full" />
			<div className="min-h-screen flex flex-col justify-center items-center gap-6">
				<img
					alt="No access, damn"
					src={process.env.PUBLIC_URL + '/facepalm_tshirt.png'}
					width="480"
					height="360"
					className="rounded-lg mb-10"
				/>
				<Text>
					<span>You have not been assigned access to any channels. </span>
					<a href="mailto:support@crunch.fashion" className="underline">
						Please contact support@crunch.fashion.
					</a>
				</Text>
				<Text>
					<span>You may need to log out for changes to take effect. </span>
				</Text>
				<StratosLogOutButton />
			</div>
		</Page>
	);
}
