/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { paths } from '../../types/backend-api';
import {
	PhaseDataType,
	PhasesDataType,
	SeasonDataType,
	UpdateSeasonAndPhasesPayload,
} from '../pages/strategy/types/seasons';
import api from './api';

type CreatePhasePayload =
	paths['/v2/phases']['post']['requestBody']['content']['application/json'];
export type CreatePhaseResponse = {
	'201': paths['/v2/phases']['post']['responses']['201']['content']['application/json'];
	'422': paths['/v2/phases']['post']['responses']['422']['content']['application/json'];
};

type UpdatePhasePayload =
	paths['/v2/phases/{phase_id}']['put']['requestBody']['content']['application/json'];
type UpdateSeasonPayload =
	paths['/v2/seasons/{season_id}']['put']['requestBody']['content']['application/json'];

export const LIST_PHASES = (): Promise<PhasesDataType> => {
	return api.get(`api/v2/phases`).json();
};

export const LIST_CURRENT_SEASON = (): Promise<SeasonDataType> => {
	return api.get(`api/v2/seasons/current`).json();
};

export const UPDATE_SEASON = ({
	id,
	...payload
}: { id: string } & UpdateSeasonPayload) => {
	return api
		.put(`api/v2/seasons/${id}`, {
			json: payload,
		})
		.json();
};

export const CREATE_PHASE = (payload: CreatePhasePayload) => {
	return api
		.post(`api/v2/phases`, {
			json: payload,
		})
		.json();
};

export const UPDATE_PHASE = ({
	id,
	...payload
}: { id: string } & UpdatePhasePayload) => {
	return api
		.put(`api/v2/phases/${id}`, {
			json: payload,
		})
		.json();
};

export const UPDATE_SEASON_AND_PHASES = async ({
	seasonId,
	payload,
}: {
	seasonId: SeasonDataType['id'];
	payload: UpdateSeasonAndPhasesPayload;
}) => {
	return api
		.put(`api/v2/seasons/${seasonId}/update-season-and-phases`, {
			json: payload,
		})
		.json();
};

export const DELETE_PHASE = (phase_id: PhaseDataType['id']) => {
	return api.delete(`api/v2/phases/${phase_id}`).json();
};
