import { DELETE_BUSINESS_RULE } from 'api/business-rules';
import useChannelStore from 'hooks/channels/useChannelStore';
import { LIST_BUSINESS_RULES_QUERY_KEY } from 'hooks/queries/useBusinessRulesQuery';
import { useMutation, useQueryClient } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import { paths } from '../../../types/backend-api';

type DeleteParams =
	paths['/v2/business-rules/{business_rule_id}']['delete']['parameters']['path']['business_rule_id'];

export const DELETE_BUSINESS_RULE_MUTATION_KEY = ['deleteBusinessRule'];

const useBusinessRuleDelete = (businessRuleId: DeleteParams) => {
	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();

	const optimisticQueryKey = constructChannelQueryKey(
		activeChannel,
		LIST_BUSINESS_RULES_QUERY_KEY,
	);

	const mutation = useMutation({
		mutationKey: [
			activeChannel,
			...DELETE_BUSINESS_RULE_MUTATION_KEY,
			businessRuleId,
		],
		mutationFn: () => DELETE_BUSINESS_RULE(businessRuleId),
		onMutate: () => {
			const listBackup =
				queryClient.getQueryData<
					paths['/v2/business-rules']['get']['responses']['200']['content']['application/json']
				>(optimisticQueryKey);

			queryClient.setQueryData<
				| paths['/v2/business-rules']['get']['responses']['200']['content']['application/json']
				| undefined
			>(optimisticQueryKey, (old) => {
				if (old === undefined) {
					return old;
				}
				const optimisticListItems = [...old.items]
					.filter((item) => item.id !== businessRuleId)
					.map((item, index) => ({ ...item, priority: index }));
				return {
					...old,
					items: optimisticListItems,
				};
			});

			return { previousBusinessRules: listBackup };
		},
		onError: (err, param, context) => {
			queryClient.setQueryData(
				optimisticQueryKey,
				context?.previousBusinessRules,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				constructChannelQueryKey(activeChannel, LIST_BUSINESS_RULES_QUERY_KEY),
			);
		},
	});

	return mutation;
};

export default useBusinessRuleDelete;
