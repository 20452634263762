import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from '../../utils/cn';
import { Badge } from '../Badge/Badge';
/**
 * ------------------------------------------------------------------
 * 1.2 | COMPONENT | <STEPS SIDEBAR> (default export)
 * ------------------------------------------------------------------
 */
const StepsSidebar = (props) => {
    return (_jsxs("div", { className: "w-48 flex flex-col justify-center gap-8 p-4 bg-ca-gray-200 rounded-md ", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(Badge, { variant: props.badgeVariant, className: "w-min", children: props.badgeTitle }), _jsx("span", { className: "text-sm block text-ca-gray", children: props.title })] }), _jsx("div", { className: "flex flex-col gap-4", children: props.steps })] }));
};
export default StepsSidebar;
/**
 * ------------------------------------------------------------------
 * 2.1 | COMPONENT | <STEP>
 * ------------------------------------------------------------------
 */
export const StepButton = (props) => {
    return (_jsxs("button", { className: cn('grid grid-cols-[min-content_1fr] gap-4 py-2 px-1', props.state !== 'disabled'
            ? 'hover:bg-ca-silver rounded-md transition-colors'
            : 'cursor-not-allowed'), onClick: props.onClick, children: [_jsxs("div", { className: "place-items-center h-full px-2 grid", children: [_jsx("div", { className: cn('w-2 h-2 rounded-full ', props.state === 'clickable' && 'bg-ca-purple', props.state === 'current' && 'bg-ca-purple col-start-1 row-start-1', props.state === 'disabled' && 'bg-ca-gray-400') }), props.state === 'current' && (_jsx("div", { className: "col-start-1 row-start-1 bg-ca-purple animate-ping w-2 h-2 rounded-full" }))] }), _jsx("span", { className: cn('text-xs text-left', props.state === 'clickable' && 'text-ca-gray-500', props.state === 'current' && 'text-ca-purple', props.state === 'disabled' && 'text-ca-gray-500'), children: props.title })] }));
};
