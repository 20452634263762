/* eslint-disable */

import {
	CREATE_BUSINESS_RULE,
	GET_BUSINESS_RULE,
	GET_BUSINESS_RULES_ACTION_TYPES,
	GET_BUSINESS_RULES_GLOBAL_ACTIONS,
	GET_BUSINESS_RULES_GLOBAL_FIELDS,
	GET_BUSINESS_RULES_REPEATING_FIELDS,
	UPDATE_BUSINESS_RULE,
} from 'api/business-rules';

import {
	Checkbox,
	Conditional,
	Dropdown,
	IconButton,
	InfoIcon,
	InputWithLabel,
	ProductIcon,
	Text,
	TextInput,
	useModal,
	useToast,
	cn,
	CircularProgress,
	GavelIcon,
	Button,
	PlusIcon,
	TrashIcon,
	RepeatIcon,
	Tooltip,
	CheckmarkIcon,
	SingleTrack,
	DoubleTrack
} from 'crunch-components';
import { id, range } from 'crunch-utils';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import { LIST_BUSINESS_RULES_QUERY_KEY } from 'hooks/queries/useBusinessRulesQuery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactSlider from "react-slider";

const mdIncrements = [
	0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
	0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
];

const labelClassName = 'w-60 mt-2.5';

// these props make this component a bit messy
// but this bussiness logic should have been inside BusinessRuleCreateEditModalBase.tsx
const BusinessRulesCreateEdit = ({ formRef, setIsLoading }) => {
	const navigate = useNavigate();
	const { close: closeModal } = useModal();
	const businessRuleId = new URLSearchParams(useLocation().search).get(
		'business-rule-id',
	);
	const isEditMode = !!businessRuleId;
	const [segments, setSegments] = useState({});
	const [possibleDiscounts, setPossibleDiscounts] = useState([...mdIncrements]);
	const [applyRule, setApplyRule] = useState(false);

	const {
		formState,
		setValue,
		control,
		handleSubmit,
		watch,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: {
			title: '',
			action_type: null,
			active: false,
			custom_fixed_action: {
				fixed_discount: 0.0,
			},
			custom_minmax_action: {
				min_discount: null,
				max_discount: null,
			},
			custom_possible_action: {
				markdowns: [],
			},
			custom_average_action: {
				average_discount: 0.5,
			},
			custom_distribution_action: {
				limiter: null,
				distribution: '',
				discount: null,
				type: null,
				field: null,
			},
			custom_min_change_action: {
				min_change_discount: 0.0,
			},
			custom_max_increase_action: {
				max_increase_discount: 0.0,
			},
			global_action_id: null,
			conditions: [],
		},
	});
	const queryClient = useQueryClient();
	const toast = useToast();

	const { data, isFetching, channel } = useChannelQuery(
		['business-rule', businessRuleId],
		() => GET_BUSINESS_RULE(businessRuleId),
		{
			enabled: isEditMode,
			onError: async ({ response }) => {
				if (response?.status === 404) {
					navigate('strategy/business-rules');
				}
			},
			retry: false,
			// In the form global business rules are added as distinct types.
			// We need to mock that situation in data as well so the initial form state is correct.
			select: (br) => {
				if (br.action_type === 'Global') {
					br.action_type = br.global_action.description;
				}
				return br;
			},
		},
	);

	useEffect(() => {
		if (data) {
			Object.entries(data).forEach(([key, val]) => {
				if (key === 'conditions') {
					setSegments(val?.reduce((acc, v) => ({ ...acc, [id()]: v }), {}));
				} else if (key === 'custom_possible_action') {
					setPossibleDiscounts(val?.markdowns || []);
				} else if (key === 'custom_distribution_action') {
					setValue(key, {
						...val,
						distribution: val?.distribution * 100 || '',
					});
					setApplyRule(val?.field !== null);
				} else {
					setValue(key, val);
				}
			});
		}
	}, [data]);

	const { data: actionTypes = [], isFetching: isActionTypesFetching } =
		useChannelQuery(
			['business-rules-action-types'],
			GET_BUSINESS_RULES_ACTION_TYPES,
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	const { data: globalActions, isFetching: isGlobalActionsFetching } =
		useChannelQuery(
			['business-rules-globalactions'],
			GET_BUSINESS_RULES_GLOBAL_ACTIONS,
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	const { data: fields, isFetching: isFieldsFetching } = useChannelQuery(
		['business-rules-fields'],
		GET_BUSINESS_RULES_GLOBAL_FIELDS,
		{
			staleTime: 5 * 60 * 1000,
		},
	);

	const { data: repeatingFields, isFetching: isRepeatingFieldsFetching } =
		useChannelQuery(
			['business-rules-repeating-fields'],
			GET_BUSINESS_RULES_REPEATING_FIELDS,
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	const {
		isLoading: isCreateLoading,
		isSuccess: isCreateSuccess,
		mutate: createBusinessRule,
	} = useMutation(CREATE_BUSINESS_RULE, {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			setIsLoading(true);
			const queryKey = [channel, ...LIST_BUSINESS_RULES_QUERY_KEY];
			await queryClient.cancelQueries(queryKey);

			// Snapshot the previous value
			const previousBusinessRules = queryClient.getQueryData(queryKey);

			if (previousBusinessRules) {
				// Optimistically update to the new value
				console.log('setQueryData');
				queryClient.setQueryData(queryKey, (old) => {
					return {
						...old,
						items: [
							...(old.items || []),
							{
								...payload,
								priority: (old.items?.[0]?.priority || 0) + 1,
							},
						],
						total: old.total + 1,
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousBusinessRules };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
				context.previousBusinessRules,
			);

			toast.show('Failed to update business rule', { type: 'error' });
		},
		onSuccess: () => {
			// maybe also show a toast
			toast.show('Business rule created', { type: 'success' });
			closeModal();
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([
				channel,
				...LIST_BUSINESS_RULES_QUERY_KEY,
			]);
			setIsLoading(false);
		},
	});

	const {
		isLoading: isEditLoading,
		isSuccess: isEditSuccess,
		mutate: editBusinessRule,
	} = useMutation((payload) => UPDATE_BUSINESS_RULE(payload.id, payload), {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			setIsLoading(true);
			const queryKey = [channel, ...LIST_BUSINESS_RULES_QUERY_KEY];
			await queryClient.cancelQueries(queryKey);

			// Snapshot the previous value
			const previousBusinessRules = queryClient.getQueryData(queryKey);

			if (previousBusinessRules) {
				// Optimistically update to the new value
				queryClient.setQueryData(
					[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
					(old) => {
						if (!old) return old;

						return {
							...old,
							items: (old?.items || []).map((item) => {
								if (item.id === payload.id) {
									return {
										...item,
										...payload,
									};
								}

								return item;
							}),
						};
					},
				);
			}

			// Return a context object with the snapshotted value
			return { previousBusinessRules };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
				context.previousBusinessRules,
			);
			toast.show('Business rule failed to update', { type: 'error' });
		},
		onSuccess: (data) => {
			toast.show('Business rule has been updated');
			closeModal();
		},
		// Always refetch after error or success:

		onSettled: () => {
			queryClient.invalidateQueries([
				channel,
				...LIST_BUSINESS_RULES_QUERY_KEY,
			]);
			setIsLoading(false);
		},
	});

	const watchType = watch('action_type');

	const addSegment = () => {
		setSegments((segs) => ({
			...segs,
			[id()]: { field: null, operator: null, value: null },
		}));
	};

	const editSegment = (sId, newValue) => {
		clearErrors(sId);
		setSegments((segs) => ({ ...segs, [sId]: newValue }));
	};

	const removeSegment = (sId) => {
		clearErrors(sId);
		setSegments((segs) => {
			const { [sId]: segmentToRemove, ...remainingSegments } = segs;

			return remainingSegments;
		});
	};

	const onSubmit = async ({
		custom_fixed_action_id: _customFixedActionId,
		custom_minmax_action_id: _customMinMaxActionId,
		custom_possible_action_id: _customPossibleActionId,
		custom_average_action_id: _customAverageActionId,
		custom_distribution_action_id: _customDistributionActionId,
		...d
	}) => {
		clearErrors([
			'custom_minmax_action.min_discount',
			'custom_minmax_action.max_discount',
			'custom_fixed_action.fixed_discount',
			'custom_possible_action.markdowns',
			'custom_average_action.average_discount',
			'custom_average_action.custom_distribution_action',
			'custom_min_change_action.min_change_discount',
			'custom_max_increase_action.max_increase_discount',
		]);

		const errors = [];

		if (
			d?.action_type === 'Custom_minmax' ||
			d?.action_type === 'Custom_fixed' ||
			d?.action_type === 'Custom_min_change' ||
			d?.action_type === 'Custom_max_increase'
		) {
			[
				[
					'custom_minmax_action.min_discount',
					d?.custom_minmax_action?.min_discount,
				],
				[
					'custom_minmax_action.max_discount',
					d?.custom_minmax_action?.max_discount,
				],
				[
					'custom_fixed_action.fixed_discount',
					d?.custom_fixed_action?.fixed_discount,
				],
				[
					'custom_min_change_action.min_change_discount',
					d?.custom_min_change_action?.min_change_discount,
				],
				[
					'custom_max_increase_action.max_increase_discount',
					d?.custom_max_increase_action?.max_increase_discount,
				],
			].forEach(([key, value]) => {
				if (value === undefined || value === null || value === '') {
					return null;
				}

				if (Number.isNaN(parseFloat(value))) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be a valid number',
						},
					]);
				}

				if (value > 0.95 || value < 0) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be between 0 - 95 %',
						},
					]);
				}

				return null;
			});
		}

		if (d?.action_type === 'Custom_minmax') {
			if (
				Number.isNaN(parseFloat(d?.custom_minmax_action?.min_discount)) &&
				Number.isNaN(parseFloat(d?.custom_minmax_action?.max_discount))
			) {
				errors.push(
					[
						'custom_minmax_action.min_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					],
					[
						'custom_minmax_action.max_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					],
				);
			}
		}

		if (d?.action_type === 'Custom_possible' && !possibleDiscounts.length) {
			errors.push([
				'custom_possible_action.markdowns',
				{
					type: 'validate',
					message: 'At least 1 markdown must be selected',
				},
			]);
		}

		if (d?.action_type === 'Custom_distribution') {
			const distribution = parseInt(
				d?.custom_distribution_action?.distribution,
				10,
			);

			if (
				Number.isNaN(distribution) ||
				distribution < 0 ||
				distribution > 100
			) {
				errors.push([
					'custom_distribution_action.distribution',
					{
						type: 'validate',
						message: 'Value must be between 0 - 100 %',
					},
				]);
			}
		}

		Object.entries(segments).forEach(([key, segment]) => {
			clearErrors(key);
			if (!segment.field) {
				return errors.push([
					key,
					{
						type: 'field',
						message: 'Field is required',
					},
				]);
			}

			if (!segment.operator) {
				return errors.push([
					key,
					{
						type: 'operator',
						message: 'Operator is required',
					},
				]);
			}

			if (segment.value !== 0 && !segment.value) {
				return errors.push([
					key,
					{
						type: 'value',
						message: 'Value is required',
					},
				]);
			}

			return null;
		});

		if (
			globalActions.items.some(
				(globalAction) => d?.action_type === globalAction.description,
			) &&
			d.global_action_id == null
		) {
			errors.push([
				'action_type',
				{
					type: 'global',
					message: 'No global action was selected.',
				},
			]);
		}

		if (errors.length) {
			errors.forEach(([key, err]) => setError(key, err));

			// bail out of submission when custom validation errors were triggered
			return null;
		}
		const payload = {
			...d,
			// Global actions are selectable like other action types in the dropdown but the real action_type for these should be 'Global'
			action_type: globalActions.items.some(
				(globalAction) => globalAction.description === d.action_type,
			)
				? 'Global'
				: d.action_type,
			custom_possible_action: {
				markdowns: possibleDiscounts.sort(),
			},
			custom_distribution_action: {
				...d?.custom_distribution_action,
				distribution:
					parseInt(d?.custom_distribution_action?.distribution, 10) / 100,
				field: applyRule ? d?.custom_distribution_action?.field : null,
			},
			conditions: Object.values(segments),
		};

		const finalPayload = {
			title: payload.title,
			tag_id: payload.tag_id,
			action_type: payload.action_type,
			conditions: payload.conditions,
		};

		if (payload.action_type === 'Global')
			finalPayload.global_action_id = payload.global_action_id;
		if (payload.action_type === 'Custom_min_change')
			finalPayload.custom_min_change_action = payload.custom_min_change_action;
		if (payload.action_type === 'Custom_max_increase')
			finalPayload.custom_max_increase_action =
				payload.custom_max_increase_action;
		if (payload.action_type === 'Custom_fixed')
			finalPayload.custom_fixed_action = payload.custom_fixed_action;
		if (payload.action_type === 'Custom_minmax')
			finalPayload.custom_minmax_action = payload.custom_minmax_action;
		if (payload.action_type === 'Custom_possible')
			finalPayload.custom_possible_action = payload.custom_possible_action;
		if (payload.action_type === 'Custom_average')
			finalPayload.custom_average_action = payload.custom_average_action;
		if (payload.action_type === 'Custom_distribution')
			finalPayload.custom_distribution_action =
				payload.custom_distribution_action;

		if (payload.id) finalPayload.id = payload.id;
		if (payload.priority) finalPayload.priority = payload.priority;

		isEditMode
			? editBusinessRule(finalPayload)
			: createBusinessRule(finalPayload);
	};

	const handleAnimationEnded = () => {
		if (isCreateSuccess || isEditSuccess) {
			navigate('/strategy/business-rules');
		}
	};

	const handleActiveMarkdownChange = (md) => {
		if (possibleDiscounts.includes(md)) {
			setPossibleDiscounts((pD) => pD.filter((aMd) => aMd !== md));
		} else {
			setPossibleDiscounts((pD) => [...pD, md]);
		}
	};

	const getOptions = (fField) => {
		const field = fields?.find(({ id: fieldId }) => fField === fieldId);

		if (field?.type === 'Checkbox') {
			return field?.checklist_options?.map((o) => ({
				value: o,
				label: o,
			}));
		}

		if (field?.type === 'List') {
			return field?.list_options?.map((o) => ({
				value: o,
				label: o,
			}));
		}

		return [];
	};
	console.log({ formstateerrors: formState?.errors });

	return (
		<>
			{/* <div className="absolute left-32 right-0 top-0 overflow-hidden rounded-lg">
				<LinearProgress
					visible={isCreateLoading || isEditLoading || isFetching}
					onAnimationEnded={handleAnimationEnded}
				/>
			</div> */}
			<form
				className="flex flex-col gap-4"
				ref={formRef}
				onSubmit={handleSubmit(onSubmit)}
			>
				<InputWithLabel
					labelPosition="label-above-input"
					label="Rule name"
					htmlFor="title"
					labelClassName={labelClassName}
				>
					<Controller
						name="title"
						control={control}
						rules={{ required: 'Required field' }}
						render={({ field }) => (
							<TextInput
								id="title"
								className="w-full sm:w-64"
								value={field.value}
								onChange={(val) => field.onChange(val)}
								error={formState?.errors?.title?.message}
							/>
						)}
					/>
				</InputWithLabel>
				<Conditional
					label={
						<span className="flex gap-2 items-center">
							<ProductIcon className="h-4 w-4" /> SELECT PRODUCTS
						</span>
					}
				>
					<InputWithLabel
						className="mb-4"
						labelPosition="label-above-input"
						labelClassName={labelClassName}
						label="Segment"
						htmlFor="segment"
					>
						<div className="space-y-5">
							{isFieldsFetching || isRepeatingFieldsFetching || isFetching ? (
								<CircularProgress />
							) : (
								<>
									{!Object.keys(segments).length && (
										<Button
											variant="unstyled"
											onClick={addSegment}
											className={cn(
												' border-ca-gray-300 rounded  p-4 border-1/2 bg-ca-gray-100 shadow-md border-2 hover:bg-ca-gray-200 flex gap-4 items-center hover:border-ca-gray-300 border-dashed',
												'focus-visible:border-ca-gray-400  focus-visible:bg-ca-gray-300 focus-visible:bg-opacity-10',
											)}
										>
											<PlusIcon className="h-4 w-4" />
											<Text type="secondary">
												No segment(s) added, click{' '}
												<span className="underline">here</span> to create one.
											</Text>
										</Button>
									)}
									{!!Object.keys(segments).length &&
										Object.entries(segments).map(
											([sId, segment], index, allSegments) => (
												<div className="flex flex-col relative pl-13" key={sId}>
													<div className="w-full flex flex-wrap space-y-2 md:space-y-0 md:space-x-3 xl:w-auto">
														<Dropdown
															placeholder="Field"
															className="w-full md:w-48 lg:w-64"
															contentClassName="max-h-[254px]"
															value={segment?.field}
															onChange={(val) =>
																editSegment(sId, {
																	...segment,
																	field: val,
																})
															}
															options={fields?.map((f) => ({
																value: f?.id,
																label: f?.name,
															}))}
															error={formState?.errors?.[sId]?.type === 'field'}
														/>
														{segment?.field && (
															<Dropdown
																placeholder="Operator"
																className="w-full md:w-40"
																value={segment?.operator}
																onChange={(val) =>
																	editSegment(sId, {
																		...segment,
																		operator: val,
																	})
																}
																options={(
																	{
																		Checkbox: [
																			'is in',
																			'is not in',
																			'contains',
																		],
																		List: ['is in', 'is not in', 'contains'],
																		Range: ['is less than', 'is greater than'],
																		Date: ['is before', 'is after'],
																	}?.[
																	fields?.find(
																		({ id }) => segment?.field === id,
																	)?.type
																	] || []
																).map((o) => ({
																	value: o,
																	label: o,
																}))}
																error={
																	formState?.errors?.[sId]?.type === 'operator'
																}
															/>
														)}
														{['is less than', 'is greater than'].includes(
															segment?.operator,
														) && (
																<div>
																	<TextInput
																		placeholder="Value"
																		id={`segment_value_${sId}`}
																		type="number"
																		min="0"
																		step="0.01"
																		className="w-full md:w-48 lg:w-64"
																		value={segment?.value}
																		onChange={({ target }) =>
																			editSegment(sId, {
																				...segment,
																				value: parseFloat(target.value),
																			})
																		}
																		error={
																			formState?.errors?.[sId]?.type === 'value'
																		}
																	/>
																</div>
															)}
														{['is before', 'is after'].includes(
															segment?.operator,
														) && (
																<div>
																	<TextInput
																		placeholder="Date"
																		type="date"
																		id={`segment_value_${sId}`}
																		className="w-full md:w-48 lg:w-64"
																		value={segment?.value || new Date()}
																		onChange={({ target }) =>
																			editSegment(sId, {
																				...segment,
																				value: target.value,
																			})
																		}
																		error={
																			formState?.errors?.[sId]?.type === 'value'
																		}
																	/>
																</div>
															)}
														{['is in', 'is not in'].includes(
															segment?.operator,
														) && (
																<Dropdown
																	placeholder="Value"
																	className="w-full md:w-48 lg:w-64"
																	multiple
																	value={segment?.value}
																	onChange={(val) =>
																		editSegment(sId, {
																			...segment,
																			value: val,
																		})
																	}
																	options={getOptions(segment?.field)}
																	error={
																		formState?.errors?.[sId]?.type === 'value'
																	}
																	searchable
																/>
															)}
														{['contains'].includes(segment?.operator) && (
															<TextInput
																placeholder="Value"
																id={`segment_value_${sId}`}
																className="w-full md:w-48 lg:w-64"
																value={segment?.value || ''}
																onChange={({ target }) =>
																	editSegment(sId, {
																		...segment,
																		value: target.value,
																	})
																}
																error={
																	formState?.errors?.[sId]?.type === 'value'
																}
															/>
														)}
														{index < allSegments.length - 1 && (
															<div className="hidden lg:block ">
																<div className="text-sm text-ca-gray font-bold bg-ca-silver p-2.5 rounded-lg">
																	AND
																</div>
															</div>
														)}
														<div className="flex-grow flex">
															<div className="inline-flex items-center justify-center ">
																<IconButton
																	icon={TrashIcon}
																	tooltip="Remove condition"
																	className="h-5"
																	onClick={() => removeSegment(sId)}
																/>
															</div>
														</div>
													</div>
													{formState?.errors?.[sId]?.message && (
														<div className="mt-1 pr-14 text-ca-red text-xs">
															{formState?.errors?.[sId]?.message}
														</div>
													)}
												</div>
											),
										)}
									{!!Object.keys(segments).length && (
										<div className="md:mr-14 md:w-48 lg:w-64">
											<Button
												variant="unstyled"
												className={cn(
													'w-full p-3 rounded-lg text-ca-purple text-sm font-bold border-2 border-dashed border-ca-purple text-center transition-colors',
													'focus-visible:border-ca-purple-a focus-visible:text-ca-purple-a focus-visible:bg-ca-purple focus-visible:bg-opacity-10',
													'hover:border-ca-purple-a hover:text-ca-purple-a hover:bg-ca-purple hover:bg-opacity-10',
													'active:text-ca-purple-a active:border-ca-purple-a active:bg-ca-purple active:bg-opacity-10',
												)}
												onClick={addSegment}
											>
												Add another segment
											</Button>
										</div>
									)}{' '}
								</>
							)}
						</div>
					</InputWithLabel>
				</Conditional>
				<Conditional
					label={
						<span className="flex gap-2 items-center">
							<GavelIcon className="h-4 w-4" />
							BUSINESS RULE
						</span>
					}
				>
					<div className="flex flex-col gap-4 mb-4">
						<InputWithLabel
							labelPosition="label-above-input"
							label="Type of rule"
							labelClassName={labelClassName}
							htmlFor="action_type"
						>
							{isActionTypesFetching || isGlobalActionsFetching ? (
								<CircularProgress />
							) : (
								<>
									<Controller
										name="action_type"
										control={control}
										rules={{ required: 'Required field' }}
										render={({ field }) => (
											<Dropdown
												placeholder="Choose"
												className="w-80"
												dropUpDown="up"
												contentClassName="max-h-[256px]"
												value={field.value}
												onChange={(val) => {
													field.onChange(val);

													// Only if selected option is a global action (special case)
													const selectedGlobalAction = globalActions.items.find(
														(globalAction) => globalAction.description === val,
													);
													if (selectedGlobalAction) {
														setValue(
															'global_action_id',
															selectedGlobalAction.id,
														);
													}
												}}
												options={[
													{
														value: 'Custom_minmax',
														label: 'Min/max. discount',
													},
													{ value: 'Custom_fixed', label: 'Fixed discount' },
													{
														value: 'Custom_min_change',
														label: 'Minimal change discount',
													},
													{
														value: 'Custom_max_increase',
														label: 'Maximal increase discount',
													},
													{
														value: 'Custom_possible',
														label: 'Possible discounts',
													},
													{
														value: 'Custom_average',
														label: 'Average discount',
													},
													{
														value: 'Custom_distribution',
														label: 'Discount distribution',
													},
													...globalActions.items.map(({ description, id }) => ({
														value: description,
														label: description,
													})),
												].filter(
													({ value }) =>
														actionTypes.includes(value) ||
														globalActions.items.some(
															({ description }) => value === description,
														),
												)}
												error={formState?.errors?.action_type?.message}
											/>
										)}
									/>
								</>
							)}
						</InputWithLabel>

						{!isGlobalActionsFetching &&
							(globalActions?.items ?? [])
								.filter(({ description }) => watchType === description)
								.map(({ description }) => {
									return (
										<div className="flex items-center gap-2">
											<CheckmarkIcon className="w-5 h-5 bg-ca-purple p-1 rounded-full	text-white stroke-2" />
											<Text type="secondary">
												Enabled rule: '{description}'
											</Text>
										</div>
									);
								})}

						{/* {watchType === 'Global' && (
							<div className="mt-4 space-y-3 w-full md:ml-60">
								{isGlobalActionsFetching ? (
									<CircularProgress />
								) : (
									<Controller
										control={control}
										render={({ field }) => (
											<>
												{globalActions?.items?.map((action) => (
													<Checkbox
														key={action?.id}
														checked={field.value?.includes(action?.id)}
														onChange={(val) => {
															if (val) {
																field.onChange([...field.value, action?.id]);
															} else {
																field.onChange(
																	field.value.filter(
																		(aId) => aId !== action?.id
																	)
																);
															}
														}}
														label={action?.description}
													/>
												))}
											</>
										)}
									/>
								)}
							</div>
						)} */}
						{watchType === 'Custom_minmax' && (
							<div className="w-full">
								<div className="flex gap-10">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Minimum discount"
										htmlFor="custom_minmax_action.min_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_minmax_action.min_discount"
											control={control}
											render={({ field }) => (
												<div className="flex items-center space-x-2">
													<Dropdown
														id="custom_minmax_action.min_discount"
														placeholder="Choose"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														options={range(0, 95, 5).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															formState?.errors?.custom_minmax_action
																?.min_discount?.message
														}
													/>
													<div className="inline-flex">
														<IconButton
															icon={RepeatIcon}
															tooltip="Reset"
															className="h-5"
															onClick={() => field.onChange(null)}
														/>
													</div>
												</div>
											)}
										/>
									</InputWithLabel>
									<InputWithLabel
										labelPosition="label-above-input"
										label="Maximum discount"
										htmlFor="custom_minmax_action.max_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_minmax_action.max_discount"
											control={control}
											render={({ field }) => (
												<div className="flex items-center gap-2">
													<Dropdown
														id="custom_minmax_action.max_discount"
														placeholder="Choose"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														options={range(0, 95, 5).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															formState?.errors?.custom_minmax_action
																?.max_discount?.message
														}
													/>
													<div className="inline-flex">
														<IconButton
															icon={RepeatIcon}
															tooltip="Reset"
															className="h-5"
															onClick={() => field.onChange(null)}
														/>
													</div>
												</div>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_fixed' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Fixed discount"
										htmlFor="custom_fixed_action.fixed_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_fixed_action.fixed_discount"
											control={control}
											rules={{ required: 'Required field' }}
											render={({ field }) => (
												<Dropdown
													id="custom_fixed_action.fixed_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													dropUpDown={'up'}
													onChange={(val) => field.onChange(val)}
													options={range(0, 95, 5).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors?.custom_fixed_action
															?.fixed_discount?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_min_change' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Minimal change in discount"
										htmlFor="custom_min_change_action.min_change_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_min_change_action.min_change_discount"
											control={control}
											rules={{ required: 'Required field' }}
											render={({ field }) => (
												<Dropdown
													id="custom_min_change_action.min_change_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													onChange={(val) => field.onChange(val)}
													options={range(0, 95, 5).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors?.custom_min_change_action
															?.min_change_discount?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_max_increase' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Maximal increase in discount"
										htmlFor="custom_max_increase_action.max_increase_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_max_increase_action.max_increase_discount"
											control={control}
											rules={{ required: 'Required field' }}
											render={({ field }) => (
												<Dropdown
													id="custom_max_increase_action.max_increase_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													onChange={(val) => field.onChange(val)}
													options={range(0, 95, 5).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors?.custom_max_increase_action
															?.max_increase_discount?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_possible' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_possible_action.markdowns"
										label="Markdowns"
										id="custom_possible_action.markdowns"
										labelClassName={labelClassName}
									>
										<div className="grid grid-cols-5 gap-y-1 gap-x-8">
											{mdIncrements.map((increment) => (
												<Checkbox
													key={increment}
													checked={possibleDiscounts.includes(increment)}
													onChange={() => {
														clearErrors('custom_possible_action.markdowns');
														handleActiveMarkdownChange(increment);
													}}
													label={`${Math.round(increment * 100)}%`}
												/>
											))}
										</div>
									</InputWithLabel>
									{formState?.errors?.custom_possible_action?.markdowns
										?.message && (
											<div className="w-full mt-2 text-ca-red text-xs text-right">
												{
													formState?.errors?.custom_possible_action?.markdowns
														?.message
												}
											</div>
										)}
								</div>
							</div>
						)}
						{watchType === 'Custom_average' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_average_action.average_discount"
										label="Discount"
										id="custom_average_action.average_discount"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_average_action.average_discount"
											control={control}
											rules={{ required: 'Required field' }}
											render={({ field }) => (
												<div className="relative flex flex-wrap justify-center items-center gap-2">
													<span className="text-xs text-ca-gray">0%</span>
													<ReactSlider
														className="mt-4 mb-8 w-64"
														min={0}
														max={100}
														value={field.value * 100}
														renderTrack={SingleTrack}
														renderThumb={({ key, ...props }, state) => (
															<div key={key}>
																<div
																	{...props}
																	className="w-4 h-4 bg-white border-2 border-ca-purple rounded-full cursor-grab focus-visible:outline-none"
																/>
																{state.valueNow > 0 && state.valueNow < 100 && (
																	<span
																		className="top-5 text-xs text-ca-gray"
																		// eslint-disable-next-line react/prop-types
																		style={props.style}
																	>
																		{state.valueNow}%
																	</span>
																)}
															</div>
														)}
														onChange={(value, ...rest) =>
															field.onChange(value / 100, ...rest)
														}
													/>
													<span className="text-xs text-ca-gray">100%</span>
												</div>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_distribution' && (
							<div className="w-full">
								<div
									className="inline-block
								space-y-4"
								>
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_distribution_action.distribution"
										label="Distribution"
										id="custom_distribution_action.distribution"
										labelClassName={labelClassName}
									>
										<div className="flex items-center">
											<Controller
												name="custom_distribution_action.limiter"
												control={control}
												rules={{ required: 'Required field' }}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.limiter"
														placeholder="Min/max"
														className="w-full md:w-32 md:mr-3"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														options={[
															{
																label: 'Min.',
																value: 'min',
															},
															{
																label: 'Max.',
																value: 'max',
															},
														]}
														error={
															!!formState?.errors?.custom_distribution_action
																?.limiter?.message
														}
													/>
												)}
											/>
											<Controller
												name="custom_distribution_action.distribution"
												control={control}
												rules={{
													required: 'Required field',
												}}
												render={({ field }) => (
													<TextInput
														id="title"
														className="w-full md:w-24"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														error={
															!!formState?.errors?.custom_distribution_action
																?.distribution?.message
														}
														endAdornment="%"
													/>
												)}
											/>
											<Text className="my-4 md:my-0 md:mx-3" type="secondary">
												of
											</Text>
											<Controller
												name="custom_distribution_action.type"
												control={control}
												rules={{ required: 'Required field' }}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.type"
														placeholder="Type"
														className="w-full md:w-28"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														options={[
															{
																value: 'products',
																label: 'Products',
															},
															{
																value: 'stock',
																label: 'Stock',
															},
														]}
														error={
															!!formState?.errors?.custom_distribution_action
																?.type?.message
														}
													/>
												)}
											/>
											<Text className="my-4 md:my-0 md:mx-3" type="secondary">
												should be discounted at
											</Text>
											<Controller
												name="custom_distribution_action.discount"
												control={control}
												rules={{ required: 'Required field' }}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.discount"
														placeholder="%"
														className="w-full md:w-28"
														value={field.value}
														onChange={(val) => field.onChange(val)}
														options={range(0, 95, 5).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															!!formState?.errors?.custom_distribution_action
																?.discount?.message
														}
													/>
												)}
											/>
										</div>
									</InputWithLabel>
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_distribution_action.field"
										label={
											<Tooltip content="You can enable this checkbox to ensure that the rule is applied for each group of the selected field.">
												<div className="flex items-center">
													Group
													<span className="inline-block ml-1.5">
														<InfoIcon className="h-3.5" />
													</span>
												</div>
											</Tooltip>
										}
										id="custom_distribution_action.field"
										labelClassName={labelClassName}
									>
										<div className="flex flex-col gap-4">
											<Checkbox
												checked={applyRule}
												onChange={() => setApplyRule(!applyRule)}
												label="Apply rule for every "
											/>
											<Controller
												name="custom_distribution_action.field"
												control={control}
												rules={{
													required: applyRule ? 'Required field' : false,
												}}
												render={({ field }) => (
													<Dropdown
														placeholder="Field"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														dropUpDown="up"
														onChange={(val) => field.onChange(val)}
														options={repeatingFields?.map((f) => ({
															value: f?.id,
															label: f?.name,
														}))}
														error={
															!!formState?.errors?.custom_distribution_action
																?.field?.message
														}
													/>
												)}
											/>
										</div>
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
				</Conditional>
				{/* <div className="flex justify-between">
					<Button
						variant="link"
						onClick={() => navigatetrategy/business-rules')}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						disabled={
							isCreateLoading ||
							isEditLoading ||
							isCreateSuccess ||
							isEditSuccess
						}
					>
						{isCreateLoading && <LoadingSpinner className="h-3 w-3" />}
						Save
					</Button>
				</div> */}
			</form>
		</>
	);
};

BusinessRulesCreateEdit.propTypes = {
	formRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.any }),
	]),
	setIsLoading: PropTypes.func,
};

BusinessRulesCreateEdit.defaultProps = {
	formRef: null,
	setIsLoading: () => { },
};

export default BusinessRulesCreateEdit;
