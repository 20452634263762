import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import cn from '../../utils/cn';
import CrossIcon from '../Icons/Cross';
import DangerIcon from '../Icons/Danger';
import useToast from './hooks/useToast';
const ToastQueue = () => {
    const { toasts, close, cleanup } = useToast();
    useEffect(() => {
        // cleanup the toast queue every 30 seconds
        const interval = setInterval(cleanup, 30 * 1000);
        return () => clearInterval(interval);
    }, []);
    return (_jsx("div", { className: "z-50 fixed left-1/2 bottom-20 transform -translate-x-1/2 flex flex-col space-y-2", children: toasts.map((toast, index) => (_jsx(Transition, { appear: true, show: !!toast?.visible, as: Fragment, enter: "transition ease-out duration-250 transform relative z-auto", enterFrom: "translate-y-full opacity-0", enterTo: "translate-y-0 opacity-250", leave: "transition ease-out duration-250 transform relative z-auto", leaveFrom: "translate-y-0 opacity-250", leaveTo: "translate-y-full opacity-0", children: _jsx("div", { className: "w-80 bg-white rounded-lg shadow-ca relative", style: { zIndex: 50 + index }, children: _jsxs("div", { className: cn('flex items-center p-3 bg-opacity-20 rounded-lg font-bold text-sm border', toast.type === 'error' && 'bg-ca-red text-ca-red border-ca-red', toast.type === 'success' &&
                        'bg-ca-green text-ca-green border-ca-green', toast.type === 'loading' &&
                        'bg-yellow-100 text-yellow-400 border-yellow-400'), children: [toast?.type === 'error' && (_jsx(DangerIcon, { className: "h-5 shrink-0" })), _jsx("span", { className: "leading-none mx-3", children: toast.message }), _jsx("button", { type: "button", onClick: () => close(toast.id), className: cn('p-2 rounded-lg transition-colors ml-auto', 'focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10', toast?.type === 'error' &&
                                'text-ca-red focus-visible:ring-ca-red hover:bg-ca-red hover:bg-opacity-10 active:bg-ca-red active:bg-opacity-10', toast?.type === 'success' &&
                                'text-ca-green focus-visible:ring-ca-green hover:bg-ca-green hover:bg-opacity-10 active:bg-ca-green active:bg-opacity-10'), children: _jsx(CrossIcon, { className: "h-2.5" }) })] }) }) }, toast.id))) }));
};
export default ToastQueue;
