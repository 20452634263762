import { Text, Title } from 'crunch-components';

const UserManagementHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title>User Management</Title>
			<Text className="leading-none" size="text-lg" type="secondary">
				Doing a lil&apos; admin work today? 👨‍💼
			</Text>
		</div>
	</div>
);

export default UserManagementHeader;
