import { components, paths } from '../../types/backend-api';
import api from './api';

export type AccessDataType =
	paths['/v2/users/my-access']['get']['responses']['200']['content']['application/json'];

export type AccessMutationType =
	paths['/v2/users/{user_id}/access']['put']['requestBody']['content']['application/json'];

export type UsersDataType =
	paths['/v2/users/']['get']['responses']['200']['content']['application/json'];

export type UserDataType = components['schemas']['User'];

export const LIST_USERS = (): Promise<UsersDataType> => {
	return api.get(`api/v2/users/`).json();
};

export const GET_MY_ACCESS = (): Promise<AccessDataType> => {
	return api.get('api/v2/users/my-access').json();
};

export const UPDATE_ACCESS = (id: string, access: AccessMutationType) => {
	return api.put(`api/v2/users/${id}/access`, {
		json: access,
	});
};
