import create from 'zustand';
import { persist } from 'zustand/middleware';

// Define the shape of the state
interface ChannelState {
	channels: string[];
	activeChannel: string;
	setChannels: (channels: string[]) => void;
	setActiveChannel: (activeChannel: string) => void;
	reset: () => void;
}

// TODO: active channel should never be allowed to be an empty string
// Create the Zustand store with persistence and TypeScript support
const useChannelStore = create<ChannelState>(
	persist(
		(set) => ({
			channels: <string[]>[],
			activeChannel: '',
			setChannels: (channels) => set({ channels }),
			setActiveChannel: (activeChannel) => set({ activeChannel }),
			reset: () => set({ channels: [], activeChannel: '' }),
		}),
		{
			name: 'ca-channels-store', // Storage key name
			whitelist: ['activeChannel', 'channels'], // Specify which parts of the state to persist
		},
	),
);

export default useChannelStore;
