import { Page } from 'crunch-components';
import UploadsHeader from '../components/UploadsHeader';
import UploadsTable from '../components/UploadsTable';

export default function UploadsView() {
	return (
		<Page>
			<UploadsHeader />
			<div className="mt-10">
				<UploadsTable />
			</div>
		</Page>
	);
}
